import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/1.jpg';
// import p2 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/2.jpg';
// import p3 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/3.jpg';
// import p4 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/4.jpg';
// import p5 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/5.jpg';
// import p6 from 'assests/Photos/Clubs/EkBharatClub/2024/Thematic/6.jpg';

// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';

import Sidebar2024 from '../EkBharat2023/components/Sidebar2024';
import Archive2023 from '../EkBharat2023/components/Sidebar2023/Archive2023';



const Thematic2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EkBharatClub/Thematic/6.webp`;



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      Thematic Display (Paired states) 
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 6 to 8  &  Date: 15 August 2024
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Knowing law is understanding the difference between knowing what is right to do, and what you have to right to do”-Potter Stewart

<br/>
        </Typography> */}
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Ek Bharat Shreshtha Bharat Association organised a vibrant in-house competition, titled "Style Symphony," to celebrate the nation's cultural diversity on the occasion of the 78th Independence Day. The event, held for students from classes 6 to 8, showcased a thematic display of famous personalities, historical monuments and the rich cultural heritage of different paired states of India.   
        <br></br>
        The performances were a seamless blend of art, music and dance, with each house portraying the essence of their paired states through vivid costumes, energetic dances and soulful music. 
        <br></br>
        The students left no stone unturned in bringing India's diverse cultural heritage to life, reflecting the true spirit of "Ek Bharat Shreshtha Bharat." Each house was judged on their creativity, accuracy in representing the paired states and the integration of various art forms.
        <br></br>
        The event was a grand success, with students gaining a deeper understanding of India's cultural richness and diversity. 
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Diversity is not about how we differ, Diversity is about embracing one another’s uniqueness”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024/>
             </Box>

             <Box marginBottom={4}>
               <Archive2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Thematic2024;